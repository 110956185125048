import axios from 'axios';
import { ID_TOKEN_KEY } from '../../../store/constant/actions.type'
import checkAuthTokenValidity from './auth.service';

export const apiServices = {
    async post(resource, params) {
        const url = process.env.VUE_APP_ENTRY_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY), "Content-Type": 'application/json' }
        // return axios.post(`${url}/${resource}`, params, { headers });
        try {
            const response = await axios.post(`${url}/${resource}`, params, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }

    }
}

export const partnerApiServices = {
    async post(resource, params) {
        const url = 'https://gateway.datagma.net/api/uaa/v1'
        const headers = { Authorization: "Basic MmJkNWIzYmQtMzg2OS00Mzc0LTgyNDYtOGE4YjNmN2VkNDYzOjlrcUhWd25NR3h6MSZlMzJRTmFRSHpTdTcqMTJvWGd1S1NYNnFeTnozZmRKJFpKV2xASG13V3phS0NvZA==", "Content-Type": 'application/json' }
        // return axios.post(`${url}/${resource}`, params, { headers });

        try {
            const response = await axios.post(`${url}/${resource}`, params, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }

    }
}

export const userVerifiedServices = {
    async post(resource, params) {
        const url = process.env.VUE_APP_ENTRY_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, params, { headers });

        try {
            const response = await axios.post(`${url}/${resource}`, params, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const userJobChangeDetectionServices = {
    async post(resource, payload) {
        const url = process.env.VUE_APP_USER_DEMO_EMAIL_DATASEARCH
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, payload, { headers });
        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const intergrationServices = {
    async post(resource, payload) {
        const url = process.env.VUE_APP_INTERGRATION_API_URL
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, payload, { headers });
        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    },
    async get(resource) {
        const url = process.env.VUE_APP_INTERGRATION_API_URL
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });
        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    },
    async delete(resource, payload) {
        const url = process.env.VUE_APP_INTERGRATION_API_URL
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.delete(`${url}/${resource}`, { data: payload, headers: headers });
        try {
            const response = await axios.delete(`${url}/${resource}`, { data: payload, headers: headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    },
    async update(resourse, payload) {
        const url = process.env.VUE_APP_NODE_URL
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resourse}`, payload, { headers });

        try {
            const response = await axios.post(`${url}/${resourse}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
export const jobChangeintergrationServices = {
    async post(resource, payload) {
        const url = process.env.VUE_APP_INTERGRATION_API_URL
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
export const subscriptionDetailServices = {
    async get(resource) {
        const url = process.env.VUE_APP_USER_DEMO_EMAIL_DATASEARCH
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });
        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    },
    async post(resource, payload) {
        const url = process.env.VUE_APP_USER_DEMO_EMAIL_DATASEARCH
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, payload, { headers });
        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}



