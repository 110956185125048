
//Lazy loading.....

const TermServices = () => import(/* webpackChunkName: "TermServices" */'../auth/term-services/term-services.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */'../auth/privacy-policy/privacy-policy.vue');
const Verification = () => import(/* webpackChunkName: "Verification" */'../auth/verification/verification.vue');
const ForgotPassword = () => import(/* webpackChunkName: "Verification" */'../auth/forgotPassword/forgotPassword.vue');
const UserEmailVerification = () => import(/* webpackChunkName: "UserEmailVerification" */'../auth/user-email-verifcation/user-email-verifcation.vue');
const signUp = () => import(/* webpackChunkName: "signUp" */'../auth/signUp/signup.vue');
const signIn = () => import(/* webpackChunkName: "Verification" */'../auth/signIn/signin.vue');
const SignUpIconoclass = () => import(/* webpackChunkName: "SignUpIconoclass" */'../auth/Sign-Up-Iconoclass/SignUpIconoclass.vue');
const VerifyOtp = () => import('../auth/verifyOtp/verifyOtpComponent.vue');
//
const authRoutes = [
    {
        path: '/',
        redirect: '/sign-in'
    },
    {
        path: '/sign-in',
        name: 'signin',
        meta: { guest: true, title: 'Sign In | datagma' },
        component: signIn
    },
    {
        path: "/Sign-Up-Iconoclass",
        name: "Sign-Up-Iconoclass",
        component: SignUpIconoclass,
        meta: { title: 'Sign Up Icono Class | datagma' }
    },
    {
        path: "/sign-up",
        name: "signup",
        component: signUp,
        meta: { title: 'Sign Up | datagma' }
    },
    {
        path: "/term-services",
        name: "term-services",
        component: TermServices,
        meta: { title: 'Term Services | datagma' }
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy | datagma' }
    },
    {
        path: "/verification",
        name: "verification",
        component: Verification,
        meta: { title: 'Verification | datagma' }
    },
    {
        path: "/email-verified/:email",
        name: "email-verified",
        component: UserEmailVerification,
        meta: { title: 'Email Verified | datagma' }
    },
    {
        path: "/forgot-Password",
        name: "forgotPassword",
        component: ForgotPassword,
        meta: { title: 'Forgot Password | datagma' }
    },
    {
        path: "/verify-Otp",
        name: "verify-Otp",
        component: VerifyOtp,
        meta: { title: 'Verify Otp | datagma' }
    },

]

export default authRoutes;