import axios from 'axios';
import { ID_TOKEN_KEY } from '../../../store/constant/actions.type'
import checkAuthTokenValidity from './auth.service';

export const userCarditServices = {
    async get(resource) {
        const url = process.env.VUE_APP_USER_CARDIT
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });

        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
export const userDetailServices = {
    async get(resource) {
        const url = process.env.VUE_APP_ENTRY_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });

        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
// process.env.VUE_APP_URL
export const userApi = {
    async get(resource) {
        const url = process.env.VUE_APP_ENTRY_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });
        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
