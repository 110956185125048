import { GET_EMAIL_DATA, ADVANCE_PHONE_SEARCH, EASY_SEARCH_ENGINE, REVEAL_FULL_PROFILE_DATA, SEARCH_CEREBRO_FRANCE_EMAIL, SEARCH_COUNTRY_BY_LOCATION, FIND_USER_EMAIL, FIND_PHONENUMBER_OF_USER, SEND_SEGMENT_MAIL } from '../constant/actions.type';
import { ADD_FILE_DATA, ADD_FILE_MOBILE_DATA, SEND__MAIL, ADD_TWITTER_DATA, ADD_JOB_CHANGE_DATA, ADD_FULL_ENRICHMENT_DATA, ADVANCE_PHONE_SEARCH_IMAGE_URL } from '../constant/actions.type';
import { userDemoServices } from '../../main/common/services/userDemo.services';
import { SET_ERROR, SET_USER_DATA } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services'

const state = {};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_DATA](state, data) {
        state.credit = data.currentCredit;
        state.errors = {};
    },
}
const containsKey = (obj, key) => Object.keys(obj).includes(key);
const actions = {
    [GET_EMAIL_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                let companyFull = true
                let companyFrench = true
                let companyPremium = true
                let personFull = false
                if (!payload.companyFull) companyFull = false
                if (!payload.companyFrench) companyFrench = false
                if (!payload.companyPremium) companyPremium = false
                if (payload.personFull) personFull = true

                let url = '';
                if (payload['user_search_data'] == "Tram Huynh") {
                    url = `/api/ingress/v2/full?apiId=` + apiId + '&companyFrench=' + companyFrench + '&companyFull=' + companyFull + '&personFull=' + personFull + '&companyPremium=' + companyPremium + '&fullName=Tram Huynh&data=airbnb&findEmail=true&companyPhone=true&source=demo_enrich_page'
                } else if (containsKey(payload['user_search_data'], 'firstName') && payload['user_search_data']['firstName'] &&
                    containsKey(payload['user_search_data'], 'lastName') && payload['user_search_data']['lastName'] &&
                    containsKey(payload['user_search_data'], 'companyName') && payload['user_search_data']['companyName']) {
                    let value = '&firstName=' + payload['user_search_data']['firstName'] + '&lastName=' + payload['user_search_data']['lastName'] + '&company=' + payload['user_search_data']['companyName']
                    url = `/api/ingress/v2/full?apiId=` + apiId + '&companyFrench=' + companyFrench + '&findEmail=false&companyPhone=true&companyFull=' + companyFull + '&personFull=' + personFull + '&companyPremium=' + companyPremium + value + '&source=demo_enrich_page'
                } else if (containsKey(payload, 'full_Api') && !payload['full_Api']) {
                    url = `/api/ingress/v2/full?data=` + payload['user_search_data'] + '&apiId=' + apiId + '&companyFrench=' + companyFrench + '&companyPremium=' + companyPremium + '&companyFull=' + companyFull + '&companyPhone=true' + '&source=demo_enrich_page'
                } else if (payload['user_search_data'].includes("linkedin.com")) {
                    url = `/api/ingress/v2/full?data=` + encodeURIComponent(payload['user_search_data']) + '&apiId=' + apiId + '&companyFrench=' + companyFrench + '&findEmail=false&companyPhone=true&companyFull=' + companyFull + '&personFull=' + personFull + '&companyPremium=' + companyPremium + '&source=demo_enrich_page'
                } else {
                    url = `/api/ingress/v2/full?data=` + payload['user_search_data'] + '&apiId=' + apiId + '&companyFrench=' + companyFrench + '&findEmail=false&companyPhone=true&companyFull=' + companyFull + '&personFull=' + personFull + '&companyPremium=' + companyPremium + '&source=demo_enrich_page'
                }
                userDemoServices.mainPost(url).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [ADVANCE_PHONE_SEARCH](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                let url = `/api/ingress/v1/search?apiId=${apiId}&source=advancedsearch`;
                // let url = `/api/ingress/v1/search?apiId=qweqweqwe`;
                if (payload.email)
                    url = `${url}&email=${payload.email}&minimumMatch=1`
                if (payload.phone)
                    url = `${url}&phone=${payload.phone}`
                if (payload.firstName)
                    url = `${url}&firstName=${payload.firstName}`
                if (payload.middleName)
                    url = `${url}&middleName=${payload.middleName}`
                if (payload.lastName)
                    url = `${url}&lastName=${payload.lastName}`
                if (payload.country)
                    url = `${url}&country=${payload.country}`
                if (payload.state)
                    url = `${url}&state=${payload.state}`
                if (payload.city)
                    url = `${url}&city=${payload.city}`
                // if (payload.otherCountry)
                //     url = `${url}&minimumMatch=0.1`
                if (payload.username) {
                    let linkedInUrl = payload.username;
                    if (linkedInUrl.startsWith("https://")) {
                        linkedInUrl = linkedInUrl.split("https://")[1];
                    }
                    if (linkedInUrl.startsWith("www.")) {
                        linkedInUrl = linkedInUrl.split("www.")[1];
                    }
                    if (linkedInUrl.startsWith("fr.")) {
                        linkedInUrl = linkedInUrl.split("fr.")[1];
                    }
                    var lastChar = linkedInUrl.substr(-1);
                    if (lastChar == "/")
                        linkedInUrl = linkedInUrl.substring(0, linkedInUrl.lastIndexOf("/"));
                    if (linkedInUrl.includes("?")) linkedInUrl = linkedInUrl.split("?")[0];
                    if (linkedInUrl.endsWith("/en"))
                        linkedInUrl = linkedInUrl.split("/en")[0];
                    url = `${url}&username=${linkedInUrl}&minimumMatch=1`
                }
                // if (!url)
                //     reject('')
                // resolve(url)

                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },

    [SEARCH_CEREBRO_FRANCE_EMAIL](context, linkedInUrl) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                // let url = `/api/ingress/v1/france?linkedIn=${linkedInUrl}&apiId=${apiId}`;
                let url = `/api/ingress/v1/mike_dataset_user?value=${linkedInUrl}&apiId=${apiId}&type=LINKED_IN`;
                // let url = `/api/ingress/v1/france?linkedIn=${linkedInUrl}&apiId=qweqweqwe`;
                // if (!url)
                //     reject('')
                // resolve(url)
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [SEARCH_COUNTRY_BY_LOCATION](context, location) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let url = `/api/finder/v1/country?location=${location}`;
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [ADVANCE_PHONE_SEARCH_IMAGE_URL](context, resource) {
        return new Promise(((resolve, reject) => {
            let apiId = localStorage.getItem("$s");
            // let data = {apiId:apiId, phone: resource}
            if (JwtServices.authorization()) {
                // let url = `/api/ingress/v1/get_picture_whatsapp ${data}`;
                let url = `/api/ingress/v1/get_picture_whatsapp?apiId=${apiId}&phone=${resource}`;
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [EASY_SEARCH_ENGINE](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                // let url = `/api/ingress/v2/full?apiId=${apiId}&companyEmployees=true&force=true`;
                // if (payload.fullName)
                //     url = `${url}&fullName=${payload.fullName}`
                // if (payload.role)
                //     url = `${url}&employeeTitle=${payload.role}`
                // if (payload.company)
                //     url = `${url}&data=${payload.company}`
                // if (payload.country)
                //     url = `${url}&employeeCountry=${payload.country}`
                let url = `/api/ingress/v1/empfinding?apiId=${apiId}&source=easysearch`;
                let query = ''
                if (payload.fullName)
                    query = `${payload.fullName}`
                if (payload.role) {
                    if (query == '')
                        query = `${payload.role}`
                    else
                        query = `${query} ${payload.role}`
                }
                if (payload.company) {
                    if (query == '')
                        query = `${payload.company}`
                    else
                        query = `${query} ${payload.company}`
                }
                url = `${url}&query=${query}`
                if (payload.country)
                    url = `${url}&country=${payload.country}`
                url = `${url}&start=${payload.start}`
                url = `${url}&num=${payload.num}`
                // if (!url)
                //     reject('')
                // resolve(url)
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [REVEAL_FULL_PROFILE_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                // let url = `/api/ingress/v1/empfinding?apiId=${apiId}&source=easysearch`;
                let url = `/api/ingress/v2/full?apiId=${apiId}&data=${payload.linkedInUrl}&companyInfo=false&companyPremium=false&companyFull=false&companyBasic=false&companyFrench=false&personFull=true&companyEmployees=false&maxEmployeesReturn=10&findEmailV2=false&findEmailV2Country=General&employeeCountry=US&phoneFull=false`;
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },


    [FIND_USER_EMAIL](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                let url = `/api/ingress/v6/findEmail?apiId=${apiId}&findEmailV2Step=3&findEmailV2Country=General&source=datatable&force=true`;
                if (payload.fullName)
                    url = `${url}&fullName=${payload.fullName}`
                if (payload.company)
                    url = `${url}&company=${payload.company}`
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [FIND_PHONENUMBER_OF_USER](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                let apiId = localStorage.getItem("$s");
                let url = `/api/ingress/v1/search?apiId=${apiId}&force=true&minimumMatch=1`;
                if (payload.linkedInUrl)
                    url = `${url}&username=${payload.linkedInUrl}`
                userDemoServices.mainPost(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            }

        })
        )
    },
    [ADD_FILE_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('add-user-file-data', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    }, [ADD_FILE_MOBILE_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('enrich-mobile', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },
    [ADD_JOB_CHANGE_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('job-change-detection', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },
    [ADD_TWITTER_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('findTwitter', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },
    [ADD_FULL_ENRICHMENT_DATA](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('enrich-crm', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },
    [SEND_SEGMENT_MAIL](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('send-segment-mail', payload).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },

    [SEND__MAIL](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDemoServices.post('send-email', payload).then(({ data }) => {
                    context.commit(SET_USER_DATA, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },
}
export default {
    state,
    actions,
    mutations
}