
const state = {
    userCredit: 0
}

const getters = {
    getUserCredit(state) {
        return state.userCredit
    }
}

const mutations = {
    changeUserCredit(state, userCredit) {
        state.userCredit = userCredit
    },
}
const actions = {
    changeUserCredit(context, userCredit) {
        context.commit('changeUserCredit', userCredit)
    }
}



export default {
    state,
    actions,
    mutations,
    getters
}