// userModule.js
const state = {
    user: {
        firstName: "",
        lastName: "",
        fullName: "",
        email: "",
        currentCredit: "",
        phone: "",
        companyName: "",
        phoneCountryCode: "",
        countryCode: "",
        countryName: "",
        job: "",
        ip: "",
        signupCode: "",
        planId: ""
    }
}

const getters = {
    getstoreUser(state) {
        return state.user;
    }
}

const mutations = {
    setstoreUser(state, userData) {
        state.user = userData;
    },
}

const actions = {
    setStoreUserData(context, userData) {
        context.commit('setstoreUser', userData);
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
