import Vue from 'vue';
import App from './app/App.vue';
import router from './app/app-routing';
import store from "./app/store";
import Loading from 'vue-loading-overlay';
import FlashMessage from '@smartweb/vue-flash-message';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import { BootstrapVue } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Clipboard from 'v-clipboard'
import { MAIN_CONSTANT } from '../src/app/store/constant/constant.type'
import VueSimpleAlert from "vue-simple-alert";
import ToggleButton from 'vue-js-toggle-button'
export const bus = new Vue();
Vue.prototype.$eventBus = new Vue();
import VueCharts from 'vue-chartjs'
import VueSkeletonLoader from 'skeleton-loader-vue'
import VModal from 'vue-js-modal';

Vue.component('vue-skeleton-loader', VueSkeletonLoader);

// import LogRocket from 'logrocket';
// LogRocket ? LogRocket.init('6dlu2v/datagma') : console.error('LogRocket init error');
Vue.use(FlashMessage)
Vue.use(Vuelidate)
Vue.use(Clipboard)
Vue.use(BootstrapVue);
Vue.use(VueSimpleAlert);
Vue.use(VueCharts);
Vue.component('loading-overlay', Loading)

Vue.use(ToggleButton)
var Toggle = Vue.extend({
  template: '#toggle',
  props: ['toggled', 'label']
});
Vue.use(VueGoogleMaps, {
  load: {
    key: MAIN_CONSTANT.GOOGLE_MAPS_API_KEY_GOES_HERE,
    libraries: 'places',
  }
});
Vue.use(VModal);
Vue.component('switch-btn', Toggle)
Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
