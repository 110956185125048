import axios from 'axios';
// import { ID_TOKEN_KEY } from '../../../store/constant/actions.type'


export const chartServices = {
    async get(URL) {
        // const url = process.env.VUE_APP_USER_CARDIT
        // const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        return await axios.get(URL);
    }
}
