import axios from 'axios';
import { ID_TOKEN_KEY } from '../../../store/constant/actions.type';
import checkAuthTokenValidity from './auth.service';

export const fileServices = {
    async get(resource) {
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${process.env.VUE_APP_file}/${resource}`, { headers });
        try {
            const response = await axios.get(`${process.env.VUE_APP_file}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }


    },
    getFileDownload(resource, dowmloadfileType) {
        const headers = {
            url: `${process.env.VUE_APP_file}/${resource}`,
            method: "GET",
            responseType: "blob",
        }
        return new Promise((resolve, reject) => {
            axios(headers).then((response) => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                let fileName = `download${new Date().toLocaleDateString()}.${dowmloadfileType && dowmloadfileType == "excel" ? "xlsx" : dowmloadfileType
                    }`;
                fileLink.setAttribute("download", fileName);
                document.body.appendChild(fileLink);

                fileLink.click();
                resolve(true)
            }, error => {
                reject(false); error
            });

        })

    }
}
