import { JOB_CHANGE_DETECTION } from "../constant/actions.type"
import { SET_USER_JOB_CHNAGE } from "../constant/mutations.type"
import JwtServices from '../../main/common/services/jwt.services'
import { SET_ERROR, } from '../constant/mutations.type'
import { userDemoServices } from '../../main/common/services/userDemo.services'
const state = {

}
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_USER_JOB_CHNAGE](state, data) {
        state.data = data;
        state.errors = {};
    },
}
const actions = {
    [JOB_CHANGE_DETECTION](context, payload) {
        return new Promise((resolve, reject) => {
            if (JwtServices.authorization()) {
                //     userDemoServices.post('get-job-chnage-detection', payload).then(({ data }) => {
                //         context.commit(SET_USER_JOB_CHNAGE, data.code);
                //         resolve(data);
                //     }).catch((err) => {
                //         if (err.response && err.response.data) {
                //             context.commit(SET_ERROR, err.response.data.message);
                //             reject(err.response)
                //         }
                //     });
                // } else {
                //     window.location = `${process.env.VUE_APP_URL}login`;
                // }
                let apiId = localStorage.getItem("$s");
                let url = `/api/ingress/v4/update?fullName=${payload['fullName']}&companyName=${payload['company']}&apiId=${apiId}&source= jc_demo`;
                if (payload['jobTitle'] && payload['jobTitle'] != '') {
                    url = `${url}&jobTitle=${payload['jobTitle']}`
                }
                userDemoServices.mainPost(url).then(({ data }) => {
                    context.commit(SET_USER_JOB_CHNAGE, "success");
                    if (data && data.data) {
                        if (data.status && data.status == 'NEW_COMPANY') {
                            data.data.no_new_company == false
                        } else if (data.status && data.status == 'NO_NEW_COMPANY') {
                            data.data.no_new_company == true
                        }
                    }
                    resolve(data);

                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });

            }
        })
    }
}

export default {
    state,
    mutations,
    actions,
}