const state = {
    whatsappProtocol: null
}

const getters = {
    getWhatsAppPro(state) {
        return state.whatsappProtocol
    }
}

const mutations = {
    changeWhatsAppPro(state, whatsappProtocol) {
        state.whatsappProtocol = whatsappProtocol
    },
}
const actions = {
    changeWhatsAppPro(context, whatsappProtocol) {
        context.commit('changeWhatsAppPro', whatsappProtocol)
    }
}



export default {
    state,
    actions,
    mutations,
    getters
}













