import { ID_TOKEN_KEY, USER_EMAIL, REFRESH_TOKEN, EXPIRES_AT, REFRESH_EXPIRES_AT, USER_CREDIT, COMPANY_NAME, LOG_OUT } from '../../../store/constant/actions.type'
import axios from 'axios';
import store from './../../../store';

export const saveToken = token => {
    localStorage.setItem(ID_TOKEN_KEY, token);
    localStorage.setItem(EXPIRES_AT, Math.floor(new Date().getTime() / 1000.0) + 3600) // 3600
};


export const saveRefreshToken = refreshToken => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
    localStorage.setItem(REFRESH_EXPIRES_AT, Math.floor(new Date().getTime() / 1000.0) + 86400) // 86400
}
export const destroyToken = () => {
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(USER_EMAIL)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(EXPIRES_AT)
    localStorage.removeItem(REFRESH_EXPIRES_AT)
    localStorage.removeItem(USER_CREDIT);
    localStorage.removeItem(COMPANY_NAME)
}
export const authorization = async () => {
    if (localStorage.getItem(ID_TOKEN_KEY) && localStorage.getItem(ID_TOKEN_KEY) !== undefined && localStorage.getItem(REFRESH_TOKEN) && localStorage.getItem(REFRESH_TOKEN) !== undefined) {
        let refresh_expires_at = localStorage.getItem(REFRESH_EXPIRES_AT)
        let expires_at = localStorage.getItem(EXPIRES_AT)
        if (Math.floor(new Date().getTime() / 1000.0) < expires_at) {
            return true
        } else if (Math.floor(new Date().getTime() / 1000.0) < refresh_expires_at) {
            return await getRefresh_token()
        } else {
            // destroyToken();

            // Now you can use the store object
            await store.dispatch(LOG_OUT);
            window.location = `${process.env.VUE_APP_URL}sign-in`;
            return false

        }
    }
}
export const getRefresh_token = async () => {
    let BASE_URL = 'https://gateway.datagma.net/api/uaa/v1/token/refresh'
    try {
        let token = localStorage.getItem(REFRESH_TOKEN)
        const response = await axios.post(`${BASE_URL}`, { 'refreshToken': token });
        const newTodoItem = response.data;
        saveToken(newTodoItem['accessToken'])
        // localStorage.setItem(ID_TOKEN_KEY, newTodoItem['accessToken'])
        // localStorage.setItem(EXPIRES_AT, Math.floor(new Date().getTime() / 1000.0) + 3600 )
        return true
    } catch (errors) {
        destroyToken();
        window.location = `${process.env.VUE_APP_URL}sign-up`;
        return false
    }
}
export default {
    saveToken,
    destroyToken,
    authorization, saveRefreshToken, getRefresh_token
}