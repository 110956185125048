import { apiServices, userVerifiedServices, partnerApiServices } from '../../main/common/services/api.services'
import { LOG_IN, VERIFY_PHONE_OTP, CHANGE_PASSWORD, ID_TOKEN_KEY, LOG_OUT, SIGN_UP, USER_VERIFCATION, USER_EMAIL, FORGOT_PASSWORD, VERIFY_FORGOT_PASSWORD, SIGN_UP_ICONO, EMAIL_DISPOSAL, RESEND_OTP, EDIT_PHONENUMBER } from '../constant/actions.type';
import { SET_ERROR, SET_AUTH, DESTROYTOKEN, VERFICATION, FORGOTPASS, VERIFYFORGOTPASS } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services'
import axios from 'axios';


const state = {
    jwt: {},
    isAuthenticated: localStorage.getItem(ID_TOKEN_KEY) && localStorage.getItem(ID_TOKEN_KEY) !== undefined && JwtServices.authorization() ? true : false
};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = data.jwt !== '' && data.jwt !== undefined ? true : false;
        state.jwt = data.jwt;
        state.errors = {};
        JwtServices.saveToken(state.jwt)
        JwtServices.saveRefreshToken(data.refreshToken)
    },
    [DESTROYTOKEN](state) {
        JwtServices.destroyToken();
        state.isAuthenticated = false;
        state.user = {};
        state.jwt = {};
        state.errors = {};
    },
    [VERFICATION](state, data) {
        state.isAuthenticated = false;
        state.email = data.email
        state.user = {};
        state.errors = {};
    },
    [FORGOTPASS](state, data) {
        state.isAuthenticated = false;
        state.status = data
        state.user = {};
        state.errors = {};
    },
    [VERIFYFORGOTPASS](state, data) {
        state.isAuthenticated = false;
        state.status = data
        state.user = {};
        state.errors = {};
    },
}
const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    }
}
const actions = {
    [LOG_IN](context, payload) {
        return new Promise(((resolve, reject) => {
            let email = payload.email;
            let password = payload.password
            apiServices.post('login', { email, password }).then(({ data }) => {
                context.commit(SET_AUTH, data);
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
    [SIGN_UP](context, payload) {
        return new Promise(((resolve, reject) => {
            apiServices.post('user', {
                email: payload.email,
                password: payload.password,
                firstName: payload.firstName,
                lastName: payload.lastName,
                phone: payload.phoneNumber,
                phoneCountryCode: payload.phoneCountryCode,
                referralCode: payload.referralCode,
                signupCode: payload.signupCoupon,
                companyName: payload.companyName
            }).then(({ data }) => {
                context.commit(VERFICATION, data);
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
    [EMAIL_DISPOSAL](context, payload) {
        return new Promise(((resolve, reject) => {

            let emailDomain = payload.split("@")
            if (emailDomain.length > 1) {
                // axios.get('https://disposable.debounce.io/?email=' + payload).then(({ data }) => {
                axios.get('https://verifymail.io/api/' + emailDomain[1] + '?key=c95e02f459354588bb4bed8df345778b').then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err && err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err)
                    }
                });
            }
        })
        )
    },
    [VERIFY_PHONE_OTP](context, payload) {
        const url = process.env.VUE_APP_ENTRY_URL;
        payload.toPhoneNumber = "+" + payload.toPhoneNumber;
        const resource = "verify_otp";
        const params = {
            toPhoneNumber: payload.toPhoneNumber,
            otp: payload.otp
        } = payload;
        return new Promise(((resolve, reject) => {

            axios.get(`${url}/${resource}`, { params }).then(({ data }) => {
                resolve(data)
            }).catch((err) => {
                context.commit(SET_ERROR, err.response.data.message);
                reject(err)
            })
        }))
    },
    [SIGN_UP_ICONO](context, payload) {
        return new Promise(((resolve, reject) => {
            partnerApiServices.post('partner/user', {
                email: payload.email,
                password: payload.password,
                firstName: payload.firstName,
                lastName: payload.lastName,
                companyName: payload.companyName,
                phone: payload.phone,
                roleId: payload.roleId
            }).then(({ data }) => {
                context.commit(VERFICATION, data);
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
    [USER_VERIFCATION](context, encryptedEmail) {
        return new Promise(((resolve, reject) => {
            userVerifiedServices.post('verified_email', { encryptedEmail }).then(({ data }) => {
                context.commit(SET_AUTH, data);
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
    [CHANGE_PASSWORD](context, payload) {
        return new Promise(((resolve, reject) => {
            let email = localStorage.getItem(USER_EMAIL);
            let newPassword = payload['newPassword']
            let oldPassword = payload['oldPassword']
            apiServices.post('passwd', { email, oldPassword, newPassword }).then(({ data }) => {
                context.commit(DESTROYTOKEN, data);
                resolve(data);
            }).catch((err) => {
                context.commit(SET_ERROR, err);
                reject(err)
            });
        })
        )
    },

    [FORGOT_PASSWORD](context, payload) {
        return new Promise(((resolve, reject) => {
            let eamil = payload['email']
            apiServices.post('forgot_password', JSON.stringify({ email: eamil })).then(({ data }) => {
                context.commit(FORGOTPASS, data);
                resolve(data);
            }).catch((err) => {
                context.commit(SET_ERROR, err);
                reject(err)
            });
        })
        )
    },
    [VERIFY_FORGOT_PASSWORD](context, payload) {
        return new Promise(((resolve, reject) => {
            // let eamil = payload['email']
            let otp = payload['otp']
            let newPassword = payload['password']
            apiServices.post('verify_forgot_password', { otp, newPassword }).then(({ data }) => {
                context.commit(VERIFYFORGOTPASS, data);
                resolve(data);
            }).catch((err) => {
                context.commit(SET_ERROR, err);
                reject(err)
            });
        })
        )
    },

    [LOG_OUT](context) {
        context.commit(DESTROYTOKEN);
        localStorage.removeItem('$s')
        localStorage.clear();
        localStorage.removeItem('firstTime')
    },
    [EDIT_PHONENUMBER](_,payload) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_ENTRY_URL;
            const header = { Authorization: localStorage.getItem(ID_TOKEN_KEY), "Content-Type": 'application/json' }
            axios.put(`${url}/phone_number`, payload, { headers: header })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    },
    [RESEND_OTP](_,phoneNumber) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_ENTRY_URL;
            axios.get(`${url}/trigger_otp?toPhoneNumber=${phoneNumber}`,)
                .then(response => resolve(response))
                .catch(error => reject(error));
        });
    }

}

export default {
    state,
    actions,
    mutations,
    getters
}