import axios from 'axios';
import { ID_TOKEN_KEY } from '../../../store/constant/actions.type'
import checkAuthTokenValidity from './auth.service';

export const userDemoServices = {
    async post(resource, payload) {
        if (resource == 'findTwitter') {
            let url = `${process.env.VUE_APP_file}/${resource}`;
            const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
            // return axios.post(url, payload, { headers });
            try {
                const response = await axios.post(url, payload, { headers });
                return response;
            } catch (error) {
                checkAuthTokenValidity(error);
                throw error;
            }
        }

        else if (resource == 'job-change-detection') {
            let url = "https://enrich-stg.datagma.net/jdV4";
            const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
            // return axios.post(url, payload, { headers });
            try {
                const response = await axios.post(url, payload, { headers });
                return response;
            } catch (error) {
                checkAuthTokenValidity(error);
                throw error;
            }
        }
        else if (resource == 'enrich-crm') {

            let url = `${process.env.VUE_APP_file}/${resource}`;
            const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
            // return axios.post(url, payload, { headers });
            try {
                const response = await axios.post(url, payload, { headers });
                return response;
            } catch (error) {
                checkAuthTokenValidity(error);
                throw error;
            }
        }
        else if (resource == 'enrich-mobile') {
            let url = `${process.env.VUE_APP_file}/${resource}`;
            const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
            // return axios.post(url, payload, { headers });
            try {
                const response = await axios.post(url, payload, { headers });
                return response;
            } catch (error) {
                checkAuthTokenValidity(error);
                throw error;
            }
        }
        else {
            let url = `${process.env.VUE_APP_USER_DEMO_EMAIL_DATASEARCH}/${resource}`;
            const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
            // return axios.post(url, payload, { headers });
            try {
                const response = await axios.post(url, payload, { headers });
                return response;
            } catch (error) {
                checkAuthTokenValidity(error);
                throw error;
            }
        }

    },
    async mainPost(url) {
        let ingressURL = process.env.VUE_APP_INGRESS_URL
        // let ingressURL = 'https://gateway.datagma.net'
        const headers = { Authorization: 'Bearer ' + localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${ingressURL}${url}`, { headers });

        try {
            const response = await axios.get(`${ingressURL}${url}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }

}