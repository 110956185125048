export const SET_ERROR = "setError";
export const SET_AUTH = "setUser";
export const DESTROYTOKEN = "destroyToken";
export const SET_INTEGRATION = "set_integration";
export const SET_CONNECTION = "set_connection";
export const SET_CREDIT = "set_credit";
export const SET_API_ID = "set_api_id";
export const REDIRECT_CODE = "redirect_code";
export const REDIRECT_INTEGRATION_NAME = "redirect_integration_name";
export const PUSH_TO_HUBSPOT = "push_to_hubspot";
export const HUBSPOT_DETECT_JOB_CHANGES = "detect_job_changes_hubspot";
export const SET_USER_DATA = "set_user_data";
export const VERFICATION = "verification";
export const FORGOTPASS = "forgot_pass";
export const VERIFYFORGOTPASS = "verify_forgot_pass";
export const SET_USER_JOB_CHNAGE = "set_user_job_change";
//file

export const SET_JOB_CHNAGE_FileData = "SET_JOB_CHNAGE_FileData";
