import { CREATE_LIST_API, DELETE_CONTACTS_LIST_API, DELETE_LIST_API, GET_CONTACTS_LIST_API, GET_USER_LIST_API, SAVE_CONTACTS_LIST_API, GET_PHONE_ENRICHED_LOGS, GET_ENRICH_CONTACT_LIST, GET_USER_DETAILS, CONTACT_PROCESSED_STATUS, UPDATE_WHATSAPP_PROTOCOL, STOP_ENRICHMENT, GET_CONTACT_ENRICHED_LOGS, GET_CONTACT_ENRICHED_TABBED_LOGS, GET_SEARCH_CONTACT_ENRICHED, GET_WEBHOOK_LOGS, GET_WEBHOOK_EMAIL_LIST, EDIT_ENRICH_NAME, GET_WHATSAPP_PROTO } from "../constant/actions.type";
import { SET_ERROR } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services';
import { userListServices, createApiServices, saveContactApiServices, deleteApiServices, contactsListServices, deleteContactApiServices, enrichContactListing, getEnrichmentPhonelogs, restartEnrichment, getUserDetails, getEnrichmentDetailselogs, getWhatsAppProto, getEnrichmentTabbedlogs, getWHEnrichmentTabbedlogs, getWHEnrichmentEmailList, editEnrichNameServices, whatsappUpdate } from '../../main/common/services/userList.service';

const state = {
};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
}
const getters = {};
const actions = {
    [GET_USER_LIST_API](context, data) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    userListServices.getList(`api/ingress/v1/list_system?apiId=${data.apiKey}`).then(({ data }) => {
                        // userListServices.getList(`api/ingress/v1/list_system/contact?listId=6288d261cc1d7d615dd313e1&apiId=${data.apiKey}`).then(({ data }) => {
                        resolve(data);
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                            reject(err.response)
                        }
                    });
                } else {
                    reject(false)
                }
            })
        })
        )
    },

    [GET_ENRICH_CONTACT_LIST](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    enrichContactListing.getContact(`api/getContactsEnrichedList`, payload).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },

    [GET_USER_DETAILS](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    const email = localStorage.getItem("user_email")
                    getUserDetails.getUser(`api/getUser/${email}`).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [CONTACT_PROCESSED_STATUS](context, postData) {
        return new Promise(((resolve, reject) => {
            restartEnrichment.changeStatus('api/restartEnrichment', postData).then(({ data }) => {
                resolve(data)
            }).catch((err) => {
                context.commit(SET_ERROR, err.response.data);
                reject(err.response.data)
            })
        }))
    },

    [UPDATE_WHATSAPP_PROTOCOL](context, postData) {
        return new Promise(((resolve, reject) => {
            whatsappUpdate.whatsapp('api/whatapp_prot', postData).then(({ data }) => {
                resolve(data)
            }).catch((err) => {
                context.commit(SET_ERROR, err.response.data);
                reject(err.response.data)
            })
        }))
    },
    [STOP_ENRICHMENT](context, postData) {
        return new Promise(((resolve, reject) => {
            restartEnrichment.changeStatus('api/stopEnrichment', postData).then(({ data }) => {
                resolve(data)
            }).catch((err) => {
                context.commit(SET_ERROR, err.response.data);
                reject(err.response.data)
            })
        }))
    },
    [GET_CONTACT_ENRICHED_TABBED_LOGS](context, payload) {
        return new Promise(((resolve, reject) => {
            //  let id = payload.id
            JwtServices.authorization().then(res => {
                if (res) {
                    getEnrichmentTabbedlogs.getUser(`api/getContactsEnrichedTabbedLogs`, payload).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [GET_WEBHOOK_LOGS](context, payload) {
        return new Promise(((resolve, reject) => {
            //  let id = payload.id
            JwtServices.authorization().then(res => {
                if (res) {
                    getWHEnrichmentTabbedlogs.getUser(`api/getWHContactsEnrichedLogs`, payload).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [GET_WEBHOOK_EMAIL_LIST](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    getWHEnrichmentEmailList.getUser(`api/getuniqueEmail`).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },


    [GET_CONTACT_ENRICHED_LOGS](context, payload) {
        return new Promise(((resolve, reject) => {
            //  let id = payload.id
            JwtServices.authorization().then(res => {
                if (res) {
                    getEnrichmentDetailselogs.getUser(`api/getContactsEnrichedLogs_new`, payload).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [GET_PHONE_ENRICHED_LOGS](context) {
        return new Promise(((resolve, reject) => {
            //  let id = payload.id
            JwtServices.authorization().then(res => {
                if (res) {
                    getEnrichmentPhonelogs.getDetails(`api/ingress/v1/phone_cost`).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [GET_SEARCH_CONTACT_ENRICHED](context, payload) {
        return new Promise(((resolve, reject) => {
            //  let id = payload.id
            JwtServices.authorization().then(res => {
                if (res) {
                    getEnrichmentDetailselogs.getUser(`api/getSearchEnrichment`, payload).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },

    [GET_WHATSAPP_PROTO](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    getWhatsAppProto.getUser(`api/whatapp_prot`).then(({ data }) => {
                        resolve(data)
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                        }
                        reject(err.response.data)
                    })
                } else {
                    reject(false);
                }
            })
        }))
    },
    [CREATE_LIST_API](context, payload) {
        return new Promise(((resolve, reject) => {
            createApiServices.post('api/ingress/v1/list_system', {
                apiId: payload.apiId,
                user: payload.user,
                listName: payload.listName,
            }).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
    [EDIT_ENRICH_NAME](context, payload) {
        return new Promise(((resolve, reject) => {
            editEnrichNameServices.putData(`api/jobEnrichment/${payload?.UserSelectedEditData?.id}`, {
                enrichmentName: payload.enrichmentName,
            }).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },

    [SAVE_CONTACTS_LIST_API](context, payload) {
        return new Promise(((resolve, reject) => {
            saveContactApiServices.post(`api/ingress/v1/list_system/contact`, {
                apiId: payload[0].apiId,
                contacts: payload,
            }).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },

    [GET_CONTACTS_LIST_API](context, payload) {
        return new Promise(((resolve, reject) => {
            let url = `api/ingress/v1/list_system/contact?apiId=${payload.apiId}&listId=${payload.listId}`
            if (payload.limit)
                url = `${url}&limit=${payload.limit}`
            if (payload.pageId)
                url = `${url}&pageId=${payload.pageId}`
            if (payload.direction)
                url = `${url}&direction=${payload.direction}`
            contactsListServices.getList(url).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },

    [DELETE_CONTACTS_LIST_API](context, payload) {
        return new Promise(((resolve, reject) => {
            deleteContactApiServices.delete(`api/ingress/v1/list_system/contact?apiId=${payload.apiId}&listId=${payload.listId}&contactId=${payload.contactId}`).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },

    [DELETE_LIST_API](context, payload) {
        return new Promise(((resolve, reject) => {
            deleteApiServices.delete(`api/ingress/v1/list_system?apiId=${payload.apiId}&listId=${payload.listId}`).then(({ data }) => {
                resolve(data);
            }).catch((err) => {
                if (err && err.response && err.response.data) {
                    context.commit(SET_ERROR, err.response.data.message);
                    reject(err)
                }
            });
        })
        )
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}