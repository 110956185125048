const UserDemo = () =>
    import('../main/components/userdemo/userDemo.vue');
const UserApi = () =>
    import('../main/components/userapi/userApi.vue');
const UserIntegration = () =>
    import('../main/components/settings/userIntegration.vue');
const jobChangeIntegration = () =>
    import('../main/components/settings/jobChangeIntegration.vue');
const whatsAppSettings = () =>
    import('../main/components/settings/whatsAppSettings.vue');
const userLayout = () =>
    import('./layout/user-layout/userLayout.vue');
const ChangePassword = () =>
    import('../auth/changePassword/changePassword.vue');
const userJobChangeDetection = () =>
    import('../main/components/jobChangeDetection/userJobChangeDetection.vue');
const FileDownload = () =>
    import('../main/common/components/file.vue');
const refferalLink = () =>
    import('../main/common/components/refferalLink/link.vue');
// const pricing = () =>
//     import('../main/common/components/pricing');
const newPricingPage = () =>
    import('../main/common/components/pricing/newPricingPage.vue');
const userStats = () =>
    import('../main/common/components/userStats/userStats.vue');
const manageBilling = () =>
    import('../main/common/components/manageBilling/manageBilling.vue');
const subscription = () =>
    import('../main/common/components/subscription/subscription.vue');
const paymentConfirmationPage = () =>
    import('../main/common/components/paymentLink/paymentConfirmationPage.vue');
// const GetSatrted = () =>
//     import('../main/components/getSatrted/getSatrted.vue');
const GetSatrted2 = () =>
    import('../main/components/getSatrted2/getSatrted2.vue');
const Dashboard = () =>
    import('../main/components/dashboard/dashboard.vue');
const paymentLink = () =>
    import('../main/common/components/paymentLink/paymentLink.vue');
const fileUploadComponent = () =>
    import('../main/components/fileUpload/fileUploadComponent.vue');
const searchByTabs = () =>
    import('../main/components/searchByTabs/searchByTabsComponent.vue');
const fundraisingPage = () =>
    import('../main/components/fundraising/fundraisingPage.vue');
const saastrPage = () =>
    import('../main/components/saastr/saastrPage.vue');
const easySearch = () =>
    import('../main/components/easySearch/easySearchComponent.vue');
const contactsComponent = () =>
    import('../main/components/contacts/contactsComponent.vue');
const enrichComponent = () =>
    import('./components/enrichmentContact/enrichmentContactComponent.vue');

const contactDesignEnrich = () =>
    import('./components/enrichmentContact/contactDesignEnrich.vue');

const webhookEnrichmentContactComponent = () =>
    import('./components/enrichmentContact/webhookEnrichmentContactComponent.vue')
const webhookEnrichLogs = () =>
    import('./components/enrichmentContact/webhookEnrichLogs.vue')

const enrichFlippedComponent = () =>
    import('./components/enrichmentFlippedContact/enrichmentFlippedContactComponent.vue')
const designEnrichLogs = () =>
    import('./components/enrichmentFlippedContact/designEnrichLogs.vue')
const payementSuccess = ()=> import('./components/paymentSuccess/paymentSuccess.vue')
const mainRoutes = [{
    path: '',
    name: 'user',
    component: userLayout,
    redirect: 'easy-search',
    meta: { requiresAuth: true },
    children: [
        {
            path: "get-started",
            name: "get-started",
            component: GetSatrted2,
            meta: { title: "Get Started with Datagma's App to Find Contact Details | datagma" },

        },
        // {
        //     path: "get-started2",
        //     name: "get-started2",
        //     component: GetSatrted2,
        // },
        {
            path: "file-upload",
            name: "file-upload",
            component: fileUploadComponent,
            meta: { title: "Upload CSV Files Effortlessly with Our User-Friendly App | datagma" }
        },
        {
            path: "enrich-contact-old",
            name: "enrich-contact-old",
            component: enrichComponent,
            meta: { title: 'Enrich Email Addresses and Contact Details for Your Business | datagma' }
        },
        {
            path: "enrich-contact",
            name: "enrich-contact",
            component: contactDesignEnrich,
            meta: { title: 'Enrich Email Addresses and Contact Details for Your Business | datagma' }
        },
        {
            path: "hubspot-webhook-logs",
            name: "hubspot-webhook-logs",
            component: webhookEnrichmentContactComponent,
            meta: { title: 'Webhook-detail Addresses and Contact Details for Your Business | datagma' }
        },
        {
            path: "webhook-logs",
            name: "webhook-logs",
            component: webhookEnrichLogs,
            meta: { title: 'Webhook-logs Addresses and Contact Details for Your Business | datagma' }
        },
        {
            path: "enrich-details-contact-old/:id",
            name: "enrich-details-contact-old",
            component: enrichFlippedComponent,
            meta: { title: 'Enrichment Logs | datagma' }
        },
        {
            path: "enrich-details-contact/:id",
            name: "enrich-details-contact",
            component: designEnrichLogs,
            meta: { title: 'Enrichment Logs | datagma' }
        },

        {
            path: "advance-phone-search",
            name: "advance-phone-search",
            component: searchByTabs,
            meta: { title: "Advanced Phone Search - Datagma's Contact Detail Finder | datagma" }
        },
        {
            path: "easy-search",
            name: "easy-search",
            component: easySearch,
            meta: { title: "Search your Data Effortlessly to collect details with Datagma | datagma" }
        },
        {
            path: "contacts",
            name: "contacts",
            component: contactsComponent,
            meta: { title: "Streamline Your Contacts with Datagma's User-Friendly App | datagma" }
        },
        {
            path: "fundraising",
            name: "fundraising",
            component: fundraisingPage,
            meta: { title: "Access the Free Fundraising API Documentation - Datagma | datagma" }
        },
        {
            path: "saastr",
            name: "saastr",
            component: saastrPage,
            meta: { title: "Get SAASTR Europa 2022 Attendees List with Datagma | datagma" }
        },
        {
            path: "dashboard/:id",
            name: "dashboard",
            component: Dashboard,
            meta: { title: 'Dashboard | datagma' }
        },
        // {
        //     path: "pricing",
        //     name: "pricing",
        //     component: pricing,
        // },
        {
            path: "new-pricing",
            name: "new-pricing",
            component: newPricingPage,
            meta: { title: "Updated Pricing and Plans - Datagma | datagma" }
        },
        {
            path: "payement-confirmation",
            name: "payement-confirmation",
            component: paymentConfirmationPage,
            meta: { title: "Datagma Payment Comfirmation | datagma" }
        },
        {
            path: "user-api",
            name: "userApi",
            component: UserApi,
            meta: { title: "Empower Your Data Handling with Datagma User API | datagma" }
        },
        {
            path: "user-demo",
            name: "userDemo",
            component: UserDemo,
            meta: { title: "Enrich API Demo - Datagma | datagma" }
        },
        {
            path: "user-integration",
            name: "userIntegration",
            component: UserIntegration,
            meta: { title: "Datagma's User Integration for Effortless App Access | datagma" }
        },
        {
            path: "Job-change-integration",
            name: "jobChangeIntegration",
            component: jobChangeIntegration,
            meta: { title: "Job Change Integration - Datagma | datagma" }
        },

        {
            path: "whatsapp-settings",
            name: "whatsapp-settings",
            component: whatsAppSettings,
            meta: { title: "Whatsapp Protocol Settings - Datagma | datagma" }
        },
        {
            path: "user-job-change",
            name: "userJobChangeDetection",
            component: userJobChangeDetection,
            meta: { title: "User Job Change Detection with Datagma | datagma" }
        },
        {
            path: "change-password",
            name: "change-password",
            component: ChangePassword,
            meta: { title: 'Change Password | datagma' }
        },
        {
            path: "my-files",
            name: "my-files",
            component: FileDownload,
            meta: { title: "User's Downloaded Files | datagma" }
        },
        {
            path: "refferalLink",
            name: "refferalLink",
            component: refferalLink,
            meta: { title: 'Refferal Link | datagma' }
        },
        {
            path: "userStats",
            name: "userStats",
            component: userStats,
            meta: { title: "Check your stats | datagma" }
        },
        {
            path: "manageBilling",
            name: "manageBilling",
            component: manageBilling,
            meta: { title: "Billing Management on Datagma App - Control Your Payments | datagma" }
        },

        {
            path: "paymentLink/:id",
            name: "paymentLink",
            component: paymentLink,
            meta: { title: 'Payment Link | datagma' }
        },
        {
            path: "subscription",
            name: "subscription",
            component: subscription,
            meta: { title: 'Subscription | datagma' }
        },
        // {
        //     path: "*",
        //     name: "easy-search",
        //     component: easySearch,
        //     meta: { title: "Search your Data Effortlessly to collect details with Datagma | datagma" }
        // },
        {
            path : 'payment-success',
            name:'paymentSuccess',
            component : payementSuccess,
            meta : {title : 'Payment Successfull | datagma'}
        }
    ]
}]

export default mainRoutes;