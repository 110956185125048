import { GET_CHART } from '../constant/chart.type';
import { chartServices } from '../../main/common/services/chart.services';
import { SET_ERROR } from '../constant/mutations.type';
import JwtServices from '../../main/common/services/jwt.services';
const state = {
};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
}
const getters = {};
const actions = {
    [GET_CHART](context, url) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                chartServices.get(url).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                reject()
            }
        })
        )
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}