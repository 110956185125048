<template>
  <div id="app">
    <router-view />
    <FlashMessage></FlashMessage>
    <loader></loader>
  </div>
</template>

<script>
export default {
  name: "App",
  beforeMount() {
    localStorage.setItem("2", true);
  },
  components: {
    loader: () =>
      import("../../src/app/main/common/components/loader/loader.vue"),
  },
};
</script>

<style></style>
