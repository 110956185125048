import { GET_USER_CREDIT, GET_USER_API_ID, GET_USER_DETAIL } from '../constant/actions.type';
import { userCarditServices, userDetailServices, userApi } from '../../main/common/services/userApi.services'
import { SET_ERROR, SET_CREDIT, SET_API_ID } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services'
// import { Subject } from 'rxjs';
// const userCredit = new Subject();
const state = {
    apikey: ''
};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CREDIT](state, data) {
        state.credit = data.currentCredit;
        state.errors = {};
    },
    [SET_API_ID](state, data) {
        state.apikey = data.apiId;
        state.errors = {};
    },
}
const getters = {
    getApikey() {
        return state.apikey;
    }
}
const actions = {
    [GET_USER_CREDIT](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    userCarditServices.get(`mine?apiId=${payload.apiId}`).then(({ data }) => {
                        context.commit(SET_CREDIT, data);
                        // userCredit.next({ credit: data.currentCredit })
                        resolve(data);
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                            reject(err.response)
                        }
                    });
                } else {
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                    reject()
                }
            })
            // userCarditServices.get(`mine?email='${payload.email}&apiId=${payload.apiId}`).then(({ data }) => {


        })
        )
    },
    [GET_USER_DETAIL](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                userDetailServices.get(`user?email=${payload.email}`).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
                reject()
            }
        })
        )
    },
    [GET_USER_API_ID](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(async res => {
                if (res) {
                    userApi.get(`api_id`).then(({ data }) => {
                        context.commit(SET_API_ID, data);

                        resolve(data);
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                            reject(err.response)
                        }
                    });
                }
                else {
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    }

}

export default {
    state,
    actions,
    mutations,
    getters
}