// store.js
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

import auth from './module/auth.module';
import intergration from './module/intergration.module';
import userApi from './module/userApi.module';
import userDemo from './module/userDemo.module';
import userJobChangeDetection from './module/userJobChangeDetection.module';
import userListApi from './module/userListApi.module';
import file from './module/file.module';
import chart from './module/chart.module';
import subscription from './module/subscription.module';
import userCredit from './module/userCredit.module';
import userStoredData from './module/userStoredData.module';
import whatsapp from './module/whatsapp.module';

import apiTime from './module/setApiTime.module'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        intergration,
        userApi,
        userDemo,
        userJobChangeDetection,
        file,
        chart,
        userListApi,
        subscription,
        userCredit,
        userStoredData,
        whatsapp,
        apiTime
    },
    plugins: [createPersistedState()],
});
