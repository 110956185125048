import Vue from 'vue';
import VueRouter from 'vue-router';
import { authRoutes } from './auth'
import { mainRoutes } from './main'
import store from './store';
import UtilsService from '../app/main/common/services/utils.services'
import { REDIRECT_CODE, REDIRECT_INTEGRATION_NAME } from '../app/store/constant/mutations.type'
import VueCountryCode from "vue-country-code";
import VueCryptojs from 'vue-cryptojs';

Vue.use(VueRouter);
Vue.use(VueCountryCode);
Vue.use(VueCryptojs);

const routes = [...mainRoutes, ...authRoutes];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title;
    // Handle logout
    if (to.name === 'logout') {
        // Perform logout actions (e.g., clear authentication tokens)
        await store.dispatch('LOGOUT');
        next({ name: 'signin' }); // Redirect to signin after logout
        return;
    }


    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const code = UtilsService.getQueryStringValue("code");
        const integration = UtilsService.getQueryStringValue("integration");
        const fpr = UtilsService.getQueryStringValue("fpr");

        if (code && integration && code !== "" && integration !== "" && code !== null && integration !== null) {
            localStorage.setItem(REDIRECT_CODE, code);
            localStorage.setItem(REDIRECT_INTEGRATION_NAME, integration);
        }


        if (store.getters.isAuthenticated) {
            if (from && from.name == 'paymentLink') {
                setTimeout(() => {
                    location.reload();
                }, 500);
            }
            return next();
        } else {
            const isSignInRoute = to.name === 'signin';
            const isAlreadyRedirecting = from && from.name === 'signin';

            if (fpr && fpr != null && fpr !== undefined) {
                if (!isSignInRoute && !isAlreadyRedirecting) {
                    return next({ name: 'signin', query: { fpr } });
                }
            } else {
                if (!isSignInRoute && !isAlreadyRedirecting) {
                    return next({ name: 'signin' });
                }
            }
        }
    }


    return next();
});

export default router;





