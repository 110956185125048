//@ts-check
import { intergrationServices, jobChangeintergrationServices } from '../../main/common/services/api.services'
import { ADD_INTEGRATION, GET_CONNECTIONS, GET_HUBSPOT_CONTACT_LIST, REMOVE_INTEGRATION, HUBSPOT_JOB_CHANGE_INTEGRATION, START_HUBSPOT_ENRICHMENT, SAVE_CONTACT_TO_HUBSPOT, UPDATE_INTEGRATION, UPDATE_TASK_DATA } from '../constant/actions.type';
import { SET_ERROR, SET_INTEGRATION, SET_CONNECTION } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services'
import { loadingService } from "../../main/common/services/loading.service";
const state = {

};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_INTEGRATION](state, data) {
        state.jwt = data;
        state.errors = {};
    },
    [SET_CONNECTION](state, data) {
        state.jwt = data;
        state.errors = {};
    },
}
const actions = {
    [ADD_INTEGRATION](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    intergrationServices.post('connections', payload).then(({ data }) => {
                        context.commit(SET_INTEGRATION, data);
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }

            })
        }
        ))
    },
    [GET_CONNECTIONS](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    intergrationServices.get('connections ').then(({ data }) => {
                        context.commit(SET_CONNECTION, data);
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [UPDATE_INTEGRATION](context, resource) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    let data = {
                        task_creation_optional: resource
                    }
                    intergrationServices.update('api/update_tips', data).then(({ data }) => {
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [UPDATE_TASK_DATA](context, resource) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    intergrationServices.update('api/updateTaskData', resource).then(({ data }) => {
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [GET_HUBSPOT_CONTACT_LIST](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    intergrationServices.get('hubspot/contact-list').then(({ data }) => {
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [REMOVE_INTEGRATION](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    intergrationServices.delete('connections', payload).then(({ data }) => {
                        context.commit(SET_CONNECTION, data);
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [HUBSPOT_JOB_CHANGE_INTEGRATION](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    jobChangeintergrationServices.post('hubspot/job-change-detection', payload).then(({ data }) => {
                        // context.commit(SET_CONNECTION, data);
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                        loadingService.setloader(false);
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })
        })
        )
    },
    [SAVE_CONTACT_TO_HUBSPOT](context, payload) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    jobChangeintergrationServices.post('hubspot', payload).then(({ data }) => {
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                        loadingService.setloader(false);
                    });
                } else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }
            })

        })
        )
    },
    [START_HUBSPOT_ENRICHMENT](context, payload) {
        return new Promise(((resolve, reject) => {

            JwtServices.authorization().then(res => {

                if (res) {
                    jobChangeintergrationServices.post('hubspot/start-enrichment', payload).then(({ data }) => {
                        resolve(data);
                        loadingService.setloader(false);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                        loadingService.setloader(false);
                    });
                }
                else {
                    // @ts-ignore
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                }

            })



        })
        )
    },
}

export default {
    state,
    actions,
    mutations
}