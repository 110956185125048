
const state = {
    time: 1
}

const getters = {
    gettime(state) {
        return state.time
    }
}

const mutations = {
    changeApitime(state, time) {
        state.time = time
    },
}
const actions = {
    changeApitime(context, time) {
        context.commit('changeApitime', time)
    }
}



export default {
    state,
    actions,
    mutations,
    getters
}