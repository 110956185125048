import axios from 'axios';
import { ID_TOKEN_KEY } from '../../../store/constant/actions.type'
import checkAuthTokenValidity from './auth.service';

export const userListServices = {
    async getList(resource) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, { headers });
        try {
            const response = await axios.get(`${url}/${resource}`, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const enrichContactListing = {
    async getContact(resource, payload) {
        const url = process.env.VUE_APP_NODE_URL
        const token = localStorage.getItem(ID_TOKEN_KEY)
        // return axios.get(`${url}/${resource}?`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        //     params: {
        //         offset: payload.offset,
        //         page: payload.page
        //     }
        // });

        try {
            const response = await axios.get(`${url}/${resource}?`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params: {
                    offset: payload.offset,
                    page: payload.page
                }
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }



    }
}

export const restartEnrichment = {
    async changeStatus(resourse, data) {
        let payload = {
            id: data.id,
            status: data.status,
            file_name: data.file_name
        }
        const url = process.env.VUE_APP_NODE_URL
        const token = localStorage.getItem(ID_TOKEN_KEY);
        // return axios.post(`${url}/${resourse}`, payload, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     }
        // });


        try {
            const response = await axios.post(`${url}/${resourse}`, payload, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}



export const whatsappUpdate = {
    async whatsapp(resourse, data) {
        let payload = {
            protocol: data.Protocol,
        }
        const url = process.env.VUE_APP_NODE_URL
        const token = localStorage.getItem(ID_TOKEN_KEY);

        try {
            const response = await axios.post(`${url}/${resourse}`, payload, {
                headers: {
                    Authorization: 'Bearer ' + token
                },

            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}







export const getUserDetails = {
    async getUser(resource) {
        const url = process.env.VUE_APP_NODE_URL
        const token = localStorage.getItem(ID_TOKEN_KEY)
        // return axios.get(`${url}/${resource}?`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     }
        // });
        try {
            const response = await axios.get(`${url}/${resource}?`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}



export const getEnrichmentDetailselogs = {
    async getUser(resource, data) {
        const url = process.env.VUE_APP_NODE_URL
        // const url = 'http://localhost:3000'
        const token = localStorage.getItem(ID_TOKEN_KEY);
        // let paramsObject = {
        //     enrichment_id: data.id,
        //     sKeyword: data.email
        // };
        // if (data?.offset && data?.page) {
        //     paramsObject.offset = data.offset;
        //     paramsObject.page = data.page
        //     paramsObject.status = data.status
        // }
        // return axios.get(`${url}/${resource}`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        //     params: paramsObject
        // });

        try {
            const response = await axios.get(`${url}/${resource}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params: data
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}



export const getWhatsAppProto = {
    async getUser(resource) {
        const url = process.env.VUE_APP_NODE_URL
        const token = localStorage.getItem(ID_TOKEN_KEY);
        try {
            const response = await axios.get(`${url}/${resource}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },

            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}









export const getEnrichmentPhonelogs = {
    async getDetails(resource) {
        const url = process.env.VUE_APP_INGRESS_URL
        // const url = 'http://localhost:3000'
        const token = localStorage.getItem(ID_TOKEN_KEY);
        let apiid = localStorage.getItem("$s");
        // let apiid = 'N4XA64pX'
        // let paramsObject = {
        //     enrichment_id: data.id,
        //     email: data.email
        // };
        // if (data?.offset && data?.page) {
        //     paramsObject.offset = data.offset;
        //     paramsObject.page = data.page
        // }
        // return axios.get(`${url}/${resource}?apiId=${apiid}`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },

        // });



        try {
            const response = await axios.get(`${url}/${resource}?apiId=${apiid}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },

            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
export const getEnrichmentTabbedlogs = {
    async getUser(resource, data) {
        const url = process.env.VUE_APP_NODE_URL
        // const url = 'http://localhost:3000'
        const token = localStorage.getItem(ID_TOKEN_KEY);
        let paramsObject = {
            enrichment_id: data.id,
            tabbed: data.tabbed

        };
        if (data?.offset && data?.page) {
            paramsObject.offset = data.offset;
            paramsObject.page = data.page
        }
        // return axios.get(`${url}/${resource}`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        //     params: paramsObject
        // });



        try {
            const response = await axios.get(`${url}/${resource}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params: paramsObject
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const getWHEnrichmentTabbedlogs = {
    async getUser(resource, data) {
        const url = process.env.VUE_APP_NODE_URL
        // const url = 'http://localhost:3000'
        const token = localStorage.getItem(ID_TOKEN_KEY);
        let filteredParams = Object.keys(data).reduce((acc, key) => {
            if (data[key] !== "" && data[key] !== null) {
                acc[key] = data[key];
            }
            return acc;
        }, {});

        // return axios.get(`${url}/${resource}`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        //     params: filteredParams
        // });

        try {
            const response = await axios.get(`${url}/${resource}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
                params: filteredParams
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const getWHEnrichmentEmailList = {
    async getUser(resource) {
        const url = process.env.VUE_APP_NODE_URL
        // const url = 'http://localhost:3000'
        const token = localStorage.getItem(ID_TOKEN_KEY);
        // return axios.get(`${url}/${resource}`, {
        //     headers: {
        //         Authorization: 'Bearer ' + token
        //     },
        // });

        try {
            const response = await axios.get(`${url}/${resource}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                },
            });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}
export const createApiServices = {
    async post(resource, payload) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: "Basic MmJkNWIzYmQtMzg2OS00Mzc0LTgyNDYtOGE4YjNmN2VkNDYzOjlrcUhWd25NR3h6MSZlMzJRTmFRSHpTdTcqMTJvWGd1S1NYNnFeTnozZmRKJFpKV2xASG13V3phS0NvZA==", "Content-Type": 'application/json' }
        // return axios.post(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const contactsListServices = {
    async getList(resource, payload) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.get(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.get(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }


    }
}

export const deleteContactApiServices = {
    async delete(resource, payload) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.delete(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.delete(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const saveContactApiServices = {
    async post(resource, payload) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.post(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.post(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}

export const editEnrichNameServices = {
    async putData(resource, payload) {
        const url = process.env.VUE_APP_NODE_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) };
        try {
            const response = await axios.put(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
};



export const deleteApiServices = {
    async delete(resource, payload) {
        const url = process.env.VUE_APP_INGRESS_URL
        const headers = { Authorization: localStorage.getItem(ID_TOKEN_KEY) }
        // return axios.delete(`${url}/${resource}`, payload, { headers });

        try {
            const response = await axios.delete(`${url}/${resource}`, payload, { headers });
            return response;
        } catch (error) {
            checkAuthTokenValidity(error);
            throw error;
        }
    }
}