
export const LOG_IN = "log_in";
export const SIGN_UP = "sign_up";
export const USER_EMAIL = "user_email";
export const GET_USER_CREDIT = "get_user_credit";
export const GET_WHATSAPP_PROTO = "get_watsapp_proto";
export const GET_USER_API_ID = "get_user_api_id";
export const CHECK_AUTH = "check_Auth";
export const CHANGE_PASSWORD = 'change_Password'
export const ID_TOKEN_KEY = "access_token";
export const EXPIRES_AT = "expires_at";
export const REFRESH_EXPIRES_AT = "refresh_expires_at";
export const REFRESH_TOKEN = "refresh_token";
export const LOG_OUT = "log_out";
export const ADD_INTEGRATION = "add_integration";
export const GET_CONNECTIONS = "get_connections";
export const GET_HUBSPOT_CONTACT_LIST = "get_hubspot_contact_list"
export const REMOVE_INTEGRATION = "remove_integration";
export const GET_EMAIL_DATA = "get_email_data";
export const ADVANCE_PHONE_SEARCH = "advance_phone_search";
export const SEARCH_CEREBRO_FRANCE_EMAIL = "search_cerebro_france_email";
export const SEARCH_COUNTRY_BY_LOCATION = "search_country_by_location";
export const EASY_SEARCH_ENGINE = "easy_search_engine";
export const REVEAL_FULL_PROFILE_DATA = "reveal_full_profile_data";
export const FIND_USER_EMAIL = "find_user_email";
export const FIND_PHONENUMBER_OF_USER = "find_phone_number_of_user";
export const SAVE_CONTACT_TO_HUBSPOT = "save_contact_to_hubspot";
export const ADD_FILE_DATA = "add_file_data";
export const ADD_FILE_MOBILE_DATA = "add_file_mobile_data";
export const ADD_TWITTER_DATA = "add_twitter_data";
export const ADD_FULL_ENRICHMENT_DATA = "add_full_enrichment_data";
export const START_HUBSPOT_ENRICHMENT = "start_hubspot_enrichment";
export const USER_VERIFCATION = "user_verifcation";
export const FORGOT_PASSWORD = "forgot_password";
export const VERIFY_FORGOT_PASSWORD = "verify_forgot_password";
export const JOB_CHANGE_DETECTION = "job_change_detection";
export const USER_CREDIT = "user_credit";
export const SEND__MAIL = "send__mail";
export const HUBSPOT_JOB_CHANGE_INTEGRATION = "hubspot_job_change_detection";
export const SIGN_UP_ICONO = "sign_up_icono";
export const SEND_SEGMENT_MAIL = "send_segment_mail";
export const GET_USER_LIST_API = "get_user_list_api";
export const GET_ENRICH_CONTACT_LIST = "get_enrich_contact_list";
export const EDIT_ENRICH_NAME = "edit_enrich_name";
export const CREATE_LIST_API = "create_list_api";
export const SAVE_CONTACTS_LIST_API = "save_contacts_list_api";
export const GET_CONTACTS_LIST_API = "get_contacts_list_api";
export const DELETE_CONTACTS_LIST_API = "delete_contacts_list_api";
export const DELETE_LIST_API = "delete_list_api";
export const GET_CUSTOMERDETAIL = "get_customer_detail";
export const GET_WEBHOOK_EMAIL_LIST = "get_webhook_email_list";
export const CANCEL_CUSTOMER_SUBSCRIPTION = "cancel_customer_subscription";
export const EMAIL_DISPOSAL = "email_disposal";
export const GET_USER_DETAIL = "user_detail";
export const VERIFY_PHONE_OTP = "verify_phone_otp";
export const SIGN_UP_CODE = "sign_up_code";
export const CONTACT_PROCESSED_STATUS = "contact_process_status";
export const UPDATE_WHATSAPP_PROTOCOL = "update_whatsapp_protocol";
export const STOP_ENRICHMENT = "stop_enrichment";
export const PHONE_NUMBER = "phone_number";
export const COUNTRY_CODE = "country_code";
export const FIRST_TIME = "first_time";
export const COMPANY_NAME = "company_name";
export const ADD_JOB_CHANGE_DATA = "add_job_change_data";
export const GET_USER_DETAILS = "get_user_details";
export const ADVANCE_PHONE_SEARCH_IMAGE_URL = "advance_phone_search_img_url";
export const GET_CONTACT_ENRICHED_LOGS = "get_contacts_enriched_logs";
export const GET_PHONE_ENRICHED_LOGS = "get_phone_enriched_logs";
export const GET_CONTACT_ENRICHED_TABBED_LOGS = "get_contacts_enriched_tabbed_logs";
export const GET_WEBHOOK_LOGS = "get_webhook_logs";
export const GET_SEARCH_CONTACT_ENRICHED = "get_search_contacts_enriched";
export const UPDATE_INTEGRATION = "update_integration";
export const UPDATE_TASK_DATA = "update_task_data";
export const RESEND_OTP = "resend_otp";
export const EDIT_PHONENUMBER = "edit_phonenumber";






