import { GET_ALL_FILE, DOWNLOAD_FILE } from '../constant/file.type';
import { SET_JOB_CHNAGE_FileData, SET_ERROR } from '../constant/mutations.type'
import JwtServices from '../../main/common/services//jwt.services';
import { fileServices } from '../../main/common/services/file.services';
const state = {
};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_JOB_CHNAGE_FileData](state, data) {
        state.file = data.currentCredit;
        state.errors = {};
    }
}
const actions = {
    [GET_ALL_FILE](context, apiKey) {

        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    fileServices.get(`files/${apiKey || localStorage.getItem('$s')}`).then(({ data }) => {
                        // context.commit(SET_JOB_CHNAGE_FileData, data);
                        resolve(data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime)));
                    }).catch((err) => {
                        if (err.response && err.response.data) {
                            context.commit(SET_ERROR, err.response.data.message);
                            reject(err.response)
                        }
                    });
                } else {
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                    reject()
                }
            })
        })
        )
    },
    [DOWNLOAD_FILE](context, fileData) {
                return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                fileServices.getFileDownload(`dl/${fileData.id}/${fileData.downloadfileType}`, fileData.downloadfileType == "crawled" ? "csv" : fileData.downloadfileType).then(({ data }) => {
                    // context.commit(SET_JOB_CHNAGE_FileData, data);
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
                reject()
            }
        })
        )
    },
}
export default {
    state,
    actions,
    mutations
}