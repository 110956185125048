import { subscriptionDetailServices } from '../../main/common/services/api.services'
import { GET_CUSTOMERDETAIL, CANCEL_CUSTOMER_SUBSCRIPTION } from '../constant/actions.type';
import { SET_ERROR, SET_INTEGRATION, SET_CONNECTION } from '../constant/mutations.type'
import JwtServices from '../../main/common/services/jwt.services'
// import { loadingService } from "../../main/common/services/loading.service";
const state = {

};
const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_INTEGRATION](state, data) {
        state.jwt = data;
        state.errors = {};
    },
    [SET_CONNECTION](state, data) {
        state.jwt = data;
        state.errors = {};
    },
}
const actions = {
    [GET_CUSTOMERDETAIL](context) {
        return new Promise(((resolve, reject) => {
            JwtServices.authorization().then(res => {
                if (res) {
                    subscriptionDetailServices.get('chargebee-subscription').then(({ data }) => {
                        resolve(data);
                    }).catch(({ response }) => {
                        if (response && response.data) {
                            context.commit(SET_ERROR, response.data.message);
                            reject(response)
                        }
                    });
                } else {
                    window.location = `${process.env.VUE_APP_URL}sign-in`;
                    reject()
                }
            })
        })
        )
    },
    [CANCEL_CUSTOMER_SUBSCRIPTION](context, payload) {
        return new Promise(((resolve, reject) => {
            if (JwtServices.authorization()) {
                subscriptionDetailServices.post('cancel-subscription', payload).then(({ data }) => {
                    resolve(data);
                }).catch((err) => {
                    if (err.response && err.response.data) {
                        context.commit(SET_ERROR, err.response.data.message);
                        reject(err.response)
                    }
                });
            } else {
                window.location = `${process.env.VUE_APP_URL}sign-in`;
            }
        })
        )
    },

}

export default {
    state,
    actions,
    mutations
}